<template>
  <div>
    <a-modal
      title="Change Password"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      html-type="submit"
      @cancel="$emit('close', true)"
      footer=""
    >
      <a-form
        layout="vertical"
        :model="form"
        :rules="validationRules()"
        name="login"
        class="password-form"
        @finish="handleOk"
      >
        <div class="row">
          <div class="col-sm-12">
            <a-form-item name="old_password" autocomplete="off">
              <a-input-password
                v-model:value="form.old_password"
                placeholder="Current Password"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <a-form-item name="password" autocomplete="off">
              <a-input-password
                :visibilityToggle="true"
                v-model:value="form.password"
                placeholder="Enter New Password"
                @change="validPasswordPattern(form.password)"
              >
              </a-input-password>
              <div v-if="this.errors.length">
                <span style="color: red" v-for="error in errors" :key="error">
                  {{ error }}
                </span>
              </div>
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <a-form-item name="confirmPassword" autocomplete="off">
              <a-input-password
                :visibilityToggle="true"
                v-model:value="form.confirmPassword"
                placeholder="Confirm Password"
              />
            </a-form-item>
          </div>
        </div>
        <footer class="modal-footer">
          <slot name="footer">
            <a-button
              type="primary"
              size="large"
              html-type="submit"
              class="btn-block"
            >
              <span>Change Password</span>
            </a-button>
          </slot>
        </footer>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { ref, defineComponent, defineEmits } from 'vue';
import { mapActions } from 'vuex';
export default defineComponent({
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        confirmPassword: '',
      },
      errors: [],
      passwordErrorMessage: '',
    };
  },
  emits: ['close'],
  setup() {
    const modalText = ref('Content of the modal');
    const visible = ref(true);
    const confirmLoading = ref(false);
    const showModal = () => {
      visible.value = true;
    };

    const handleCancel = () => {
      // console.log('at handle');
    };
    return {
      modalText,
      visible,
      confirmLoading,
      handleCancel,
      showModal,
    };
  },

  methods: {
    ...mapActions(['RequestChangePassword']),
    async validPassword(_, password) {
      if (password && password === this.form.password) {
        return Promise.resolve();
      } else {
        return Promise.reject('Confirm password must be equal to password');
      }
    },
    async validPasswordPattern(password) {
      this.errors = [];
      if (password.length < 9) {
        this.errors.push('Your password must be at least 9 characters. ');
      }
      if ((password.match(/[A-Z]/g) || []).length < 1) {
        this.errors.push(
          'Your password must contain at least one capital letter.'
        );
      }
      if ((password.match(/[0-9]/g) || []).length < 2) {
        this.errors.push('Your password must contain at least two numbers. ');
      }
      if ((password.match(/[^\w\s]/g) || []).length < 2) {
        this.errors.push(
          'Your password must contain at least two Special characters. '
        );
      }
      if (this.errors.length > 0) {
        return false;
      }
      return Promise.resolve();
    },
    validationRules() {
      return {
        old_password: [
          {
            required: true,
            message: 'Current password is required',
          },
        ],
        password: [
          {
            required: true,
            min: 9,
            // validator: this.validPasswordPattern,
            trigger: 'submit',
            // message: 'Password should contain eight characters, at least one uppercase letter, one lowercase letter and one number',
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: this.validPassword,
            trigger: 'submit',
            message: 'Confirm password field is required',
          },
        ],
      };
    },
    async handleOk() {
      this.confirmLoading = true;
      const payload = {
        old_password: this.form.old_password,
        password: this.form.password,
        confirm_password: this.form.confirmPassword,
      };
      if (await this.RequestChangePassword(payload)) {
        this.visible = false;
        this.$emit('close', true);
      }
      this.confirmLoading = false;
    },
  },
});
</script>
<style>
.password-form input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
