<template>
  <a-layout-sider
    v-model:collapsed="sidebar"
    :collapsedWidth="50"
    :trigger="null"
    collapsible
    class="d-none d-sm-none d-md-block"
    width="250"
    theme="light"
  >
    <a-menu
      v-model:selectedKeys="currentRoute"
      class="overflow-x-hidden"
      mode="inline"
    >
      <a-menu-item class="flex disable-click logo-item">
        <img
          v-if="sidebar"
          alt="logo"
          style="height: auto"
          width="55"
          :src="logoMini"
        />
        <img v-else alt="logo" width="200" style="height: auto" :src="logo" />
      </a-menu-item>
      <a-divider class="my-3 mt-2" />
      <a-menu-item
        v-for="route in items"
        :id="route.name + '-sidelink'"
        :key="route.name"
        :style="{ 'font-weight': !route.to && 'bold' }"
        :class="{ 'disable-click': !route.to }"
        class="sidebar-item"
        @click="redirectTo(route.name)"
      >
        <span v-if="route.to" class="anticon mr-2">
          <i :class="route.class" />
        </span>
        <span>{{ route.name }}</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>
<script>
import logo from '../../assets/img/assembly-cp-logo.png';
import logoMini from '../../assets/img/assembly-cp-mini-logo.png';
import { ref } from 'vue';
import { mapGetters } from 'vuex';

export default {
  components: {},
  setup() {
    return {
      logo,
      logoMini,
      selectedKeys: ref(['2']),
    };
  },
  computed: {
    ...mapGetters([
      'sidebarItems',
      'currentRoute',
      'getSidebarState',
      'getTheme',
    ]),
    items() {
      if (this.sidebar) {
        return this.sidebarItems.filter((e) => e.to);
      }
      return this.sidebarItems;
    },
    sidebar: {
      get() {
        return this.$store.state.sidebar;
      },
      set(value) {
        this.$store.commit('updateSidebarState', value);
      },
    },
    currentRoute: {
      get() {
        return this.$store.state.currentRoute;
      },
      set(value) {
        this.$store.commit('changeRoute', value);
      },
    },
  },
  methods: {
    redirectTo(name) {
      this.$router.push({ name });
      //   this.changeRoute(name);
      //   this.closeSidebar();
    },
  },
};
</script>
<style>
aside {
  box-shadow: rgb(0 0 0 / 8%) 0.125rem 0 0.25rem;
  z-index: 11;
  overflow: auto;
  height: 100vh;
}

.logo-item {
  padding-left: 0px !important;
  padding: 0px 16px !important;
}

.mini-logo {
  margin-top: 0 !important;
  margin-bottom: 0.85em !important;
}

.sidebar-item > .ant-menu-title-content {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
