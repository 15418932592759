<template>
  <a-layout-content
    :style="{
      background: '#F2F4F8',
      height: '92vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    }"
  >
    <router-view></router-view>
  </a-layout-content>
</template>
<script>
export default {};
</script>
<style></style>
