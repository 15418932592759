<template>
  <a-button
    :type="buttonType"
    :loading="isLoading"
    style="margin-right:16px"
    @click="downloadInstaller"
  >
    {{ diagnosticTool ? 'Download Diagnostic Tool' : 'Download Installer' }}
  </a-button>
  <!-- <a-button
    :loading="isUpdateLoading"
    :type="buttonType"
    style="margin-right:16px"
    @click="downloadUpdate"
  >
    Download Update
  </a-button> -->
</template>

<script>
import generic from '../../../services/generic';
import VideoService from '../../../services/videos';
import { inject, ref } from 'vue';
import { downloadFileUsingUrl } from '../Helpers/downLoadFIleUsingUrl';

export default {
  name: 'InstallerButton',
  props: {
    buttonType: {
      type: String,
      default: 'default'
    },
    diagnosticTool: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const toast = inject('toast');
    const isLoading = ref(false);
    const isUpdateLoading = ref(false);
    const downloadInstaller = async () => {
      isLoading.value = true;
      let error = null;
      let data = null;
      if (props.diagnosticTool) {
        const payload = {
          bucket_name: `retrocausal`,
          object_path: `AssemblyCopilotDiagnostic_Release_1.0.0.zip`
        };
        [error, data] = await VideoService.getPresignedUrl(payload);
        data.url = data.presigned_url;
      } else {
        [error, data] = await generic.getInstallerUrl();
      }

      isLoading.value = false;
      error ? toast.error('An error occurred') : downloadFileUsingUrl(data.url);
    };
    const downloadUpdate = async () => {
      isUpdateLoading.value = true;
      const [error, data] = await generic.getUpdateInstallerUrl();
      isUpdateLoading.value = false;
      error ? toast.error('An error occurred') : downloadFileUsingUrl(data);
    };

    return {
      isLoading,
      isUpdateLoading,
      downloadInstaller,
      downloadUpdate
    };
  }
};
</script>

<style scoped></style>
