<template>
  <div id="mobile-menu" class="hide d-md-none">
    <a-menu v-model:selectedKeys="currentRoute" mode="inline">
      <a-menu-item>
        <!-- <img alt="logo" width="55" :src="logoMini" v-if="sidebar" /> -->
        <div class="d-flex justify-content-between align-items-center w-100">
          <img alt="logo" width="200" :src="logo" />
          <img @click="closeMenu()" width="10" :src="closeIcon"/>
        </div>
      </a-menu-item>
      <a-divider class="m-0" />
      <a-menu-item
        :key="route.name"
        v-for="route in items"
        :style="{ 'font-weight': !route.to && 'bold' }"
        :class="{ 'disable-click': !route.to }"
        @click="redirectTo(route.name)"
      >
        <span class="anticon mr-2" v-if="route.to">
          <i :class="route.class"></i
        ></span>
        <span>{{ route.name }}</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { roles } from "../../config/roles-config";
import { ref } from "vue";
import { CloseOutlined,CloseCircleOutlined } from "@ant-design/icons-vue";
import logo from "../../assets/img/assembly-cp-logo.png";
import logoMini from "../../assets/img/assembly-cp-mini-logo.png";
import closeIcon from "../../assets/icons/close.png";

export default {
  components() {
    CloseOutlined,
    CloseCircleOutlined
  },
  setup() {
    return {
      logo,
      logoMini,
      closeIcon,
      selectedKeys: ref(["2"]),
    };
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters([
      "getSidebarState",
      "currentRoute",
      "user",
      "role",
      "organization",
      "sidebarItems",
      "getTheme",
    ]),
    items() {
      if (this.sidebar) {
        return this.sidebarItems.filter((e) => e.to);
      }
      return this.sidebarItems;
    },
    sidebar: {
      get() {
        return this.$store.state.sidebar;
      },
      set(value) {
        this.$store.commit("updateSidebarState", value);
      },
    },
    currentRoute: {
      get() {
        return this.$store.state.currentRoute;
      },
      set(value) {
        this.$store.commit("changeRoute", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateSidebar"]),
    redirectTo(name) {
      this.$router.push({ name });
      this.closeMenu()
    },
    closeMenu() {
      const mobileMenu = document.getElementById("mobile-menu");
      mobileMenu?.classList.remove("show");
    },
  },
};
</script>
<style>
#mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 999999;
}
.show {
  display: block !important;
}
.hide {
  display: none;
}
</style>